import React, { Component } from 'react'
import Clock from "react-live-clock";
import UserRoleCompany from '../pages/userrolecompany';
import axios from "axios";
function logout() {
  axios.get(localStorage.getItem('host') + '/auth/deletelogin/'+localStorage.getItem('refId'), {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    } 
  }).then(
    res => {
    localStorage.clear();
    window.location.href = "/"
    }, err => {
      console.log("....."+err)
      localStorage.clear();
      window.location.href = "/"
  }
)
}
export default class Top extends Component {
    state = { showLogin: true, showNav: true, showDropDown: false, count: 0, notifications: [], showSubmit: false, username: '', usernameError: '', password: '', passwordError: '', error: '', ipAddress: '', country: '',
    userId: localStorage.getItem('userId'), admin: localStorage.getItem('admin'), refId: localStorage.getItem('refId'), companyId: localStorage.getItem('companyId'), speciality: localStorage.getItem('speciality'), roleId: localStorage.getItem('roleId'), companyName: localStorage.getItem('companyName') };
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getNotifications = this.getNotifications.bind(this)
    this.userRoleCompanyRef = React.createRef()
    }
    componentDidMount()
    {
        
    this.getNotifications()
    setInterval(this.getNotifications, 30000); //30000- 30 seconds  300000
    }
    toggleNav() {
        this.setState({ showNav: !this.state.showNav })
    }

    toggleDropDown() {
      document.getElementById('showNotificationAction').style = 'display:block'
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openUserRoleCompany()
    {
        this.setState({loader: true})
        this.userRoleCompanyRef.current.openUserRoleCompany([], localStorage.getItem("userId"))   
    }
    getNotifications()
    {
    var hasFocus = document.hasFocus()
    axios.get(localStorage.getItem('host') + '/auth/getnotifications/'+localStorage.getItem('companyId')+'/'+localStorage.getItem('roleId')+'/'+localStorage.getItem('userId')+'/'+localStorage.getItem('refId')+"/"+hasFocus, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
         , validateStatus: () => true
          }).then(
            res => {
                console.log(res.status)
                console.log(res.data)
              if(res.status === 401 || res.status === "401")
                 {
                  localStorage.clear();
                  window.location.href = "/"                    
                 }  
              else
                 {
                    if(res.data.stillLoggedIn === 0 || res.data.stillLoggedIn === "0")
                      {
                       localStorage.clear();
                       window.location.href = "/"
                      }
                    else
                      {  
                        this.playNotification(res.data.count)

                       this.setState({ notifications: res.data.notifications , count: res.data.count});
                      }
                 }   
            }, err => {
              console.log("....."+err)
           //   localStorage.clear();
           //   window.location.href = "/"
            }
          )
    }
    markAsRead(id)
      {
            axios.get(localStorage.getItem('host') + '/auth/closenotifications/'+id+'/'+localStorage.getItem('companyId')+'/'+localStorage.getItem('roleId')+'/'+localStorage.getItem('userId'), {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
             , validateStatus: () => true
              }).then(
                res => {
                    let c = Number(localStorage.getItem('oldCount')) - 1
                    localStorage.setItem("oldCount", c)
                    this.setState({ notifications: res.data.notifications , count: res.data.count});
                    this.playNotification(res.data.count)
                }, err => {
                    console.log(".....")
                  }
                )
      }
      playNotification(c)
      {
        if(Number(c) !== Number(localStorage.getItem("oldCount")) && Number(c) > 0)
          {
  
            var audio = new Audio('/audio/notification.wav');
            audio.play();
          }
      localStorage.setItem("oldCount", c)
      }

    showLogin()
     {
        axios.get('https://api.ipdata.co?api-key=212ad9acd89c4d7e42b8b70db2317c75e051aa7f16c81fed265ef9bd').then(res => {
            this.setState({ ipAddress: res.data.ip, country: res.data.country_name, isp: res.data.asn.name });
        }, err => {
          console.log(".....")
        })
        document.getElementById('loginPopup').style = 'visibility:visible'  
     }
    closePopup() {
        this.setState({showLogin: true})   
        document.getElementById('loginPopup').style = 'visibility:hidden'
    }
    closeNotificationPopup()
     {
        document.getElementById('showNotificationAction').style = 'display:none' 
     }
    login()
    {
          this.setLoader(true)
          const data = {
            username: this.state.username,
            password: this.state.password,
            ipAddress: this.state.ipAddress,
            country: this.state.country,
            isp: this.state.isp
          };
          axios.post(localStorage.getItem('host') + '/auth/authenticate', data).then(
            res => {
              if (res.data.error) {
                this.setState({ error: res.data.error });
                document.getElementById('alert').style.display = 'block';
              }
              else {
                if (res.data.error === "") {
                  localStorage.setItem('token', res.data.token);
                  localStorage.setItem('userId', res.data.userId);
                  localStorage.setItem('fullname', res.data.fullname);
                  localStorage.setItem('username', res.data.username);
                  localStorage.setItem('admin', res.data.admin);
                  localStorage.setItem('refId', res.data.refId);
                  localStorage.setItem('companyId', this.state.companyId);
                  localStorage.setItem('speciality', this.state.speciality);
                  localStorage.setItem('roleId', this.state.roleId);
                  localStorage.setItem('companyName', this.state.companyName);    
                  this.closePopup()
                  this.setLoader(false)
                }
                else {
                  this.setLoader(false)
                  this.setState({ 'error': res.data.error });
                  document.getElementById('alert').style.display = 'block';
                }
              }
            }, err => {
              this.setLoader(false)
              this.setState({ error: err + "" });
            }
    
          )
    }
    changePassword()
    {
       window.location.href = "/auth/changepassword" 
    }
    doValidate()
      {
        setTimeout(() => {
            this.validatePatient();
        }, 1000);   
      }
    validatePatient() {
       
        var error = false;
        if (this.state.username === null) {
            this.setState({ usernameError: "Username cannot be empty" });
            error = true
        }
        else if (this.state.username === "") {
            this.setState({ usernameError: "Username cannot be empty" });
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.username)))
            {
            this.setState({ usernameError: "Username should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ usernameError: "" })
        }

        if (this.state.password === null) {
            this.setState({ passwordError: "Password cannot be empty" })
            error = true
        }
        else if (this.state.password === "") {
            this.setState({ passwordError: "Password cannot be empty" })
            error = true
        }
        else {
            this.setState({ passwordError: "" })
        }
     
        if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
    }
    render() {
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
           let divMsg = ''
           if (this.state.error) {
               divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
           }
        return (
            <nav className="navbar navbar-expand-lg border-bottom" style={{ backgroundColor: '#e3f2fd', borderColor: '#08285F' }}>
                <div className="container-fluid">
                    <button className="btn btn-primary" id="sidebarToggle" style={{ backgroundColor: '#36BEEE' }}> <i className="bi bi-list"></i></button>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" onClick={() => { this.toggleNav() }} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className={(this.state.showNav ? 'show' : '') + ' collapse navbar-collapse'}>
                        <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                            <li className="nav-item nav-black" ><i className="bi bi-clock i"></i><span id="digiclock"><Clock format={'HH:mm:ss'} ticking={true} timezone={'AFRICA/Harare'} /></span></li>
                            <li className="nav-item nav-black"  ><i className="bi bi-building i"></i><span >{localStorage.getItem('companyName')}</span> </li>
                            <li className="nav-item nav-black"  ><i className="bi bi-person-circle i"></i><span >{localStorage.getItem('fullname')}</span> </li>
                            <li className="nav-item" ><span className="navLinkColor" title="Change Role Company" onClick={() => this.openUserRoleCompany()}><i className="bi bi-box-arrow-in-down-right"></i></span></li>
                            <li className="nav-item"  ><span className="navLinkColor" title="Change Password" onClick={() => this.changePassword()} ><i className="bi bi-lock-fill"></i></span></li>
                            <li className="nav-item dropdown" >
                                <a className="navLinkBell" id="navbarDropdown" href={() => false} onClick={() => { this.toggleDropDown() }}><i className="bi bi-bell-fill i"></i>
                                    <span className="top-0 translate-middle badge rounded-pill bg-danger">{this.state.count}</span>
                                </a>
                            </li>
                            <li className="nav-item"  ><span onClick={() => { logout(); }} className="navLinkColor" title="Logout"><i className="bi bi-box-arrow-right"></i></span></li>

                        </ul>
                    </div>
                </div>
        <UserRoleCompany ref={this.userRoleCompanyRef}  setLoader={this.setLoader} />
        {loaderDiv}
        <div id="loginPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Login Page</div>
                        <div className="card-body" >
                            {divMsg}
                        <div className="row">
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Username </span>
                        <input type="text" className={this.state.usernameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.username} onChange={(e) => {this.setState({ username: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.usernameError}</div></div></div>
                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Password</span>
                        <input type="password" className={this.state.passwordError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.password} onChange={(e) => {this.setState({ password: e.target.value }); this.doValidate()}} />
                        <div className="invalid-feedback">{this.state.passwordError}</div></div></div>
                </div>
                            <div className="row">
                            <div className="col-lg-6" align="center">{this.state.showSubmit === true && (<button onClick={() => this.login()} className="btn btn-primary">Login</button>) }</div>
                                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
        <div id="showNotificationAction" className="page-popup page-popup-overlay" >
                    <div className="card card-green" >
                        <div className="card-header  bg-info">Notifications</div>
                        <div className="card-body" >
                             <ul className="list-group">
                              {this.state.notifications.map((item, index) => (
                               <li className={item.className} key={index}>
                                 { item.messageStatus === 'CREATED' ?  <span style={{fontStyle: 'italic', fontWeight: 'bold' }} onClick={ () => this.markAsRead(item.id) } >{item.message}</span>: <span>{item.message}</span> }
                                   <td></td>
                                </li>  
                                  ))}
                             </ul>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.closeNotificationPopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}