import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import MyDatePicker from "../mydatepicker";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class OpdMaternityNurseAction extends Component {
    
    state = { barcode: '',billId: '', companyId: '',locationId: '', speciality: '', plocationId: '', rate: 0, institutionId: '', pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: true, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false, fileData: '', billCategory: '', results: [], locations: [], services: [], options:[], billItems: [], treatments: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', bedDetails: '', nurseNotes: '', vitals: [], notes: [], referralLetters: [], oldPatient: '', managementLists: [], historyVisits: [], visitId: 0, msg: '', error: '', diagnosisText: '', patientDiagnosis: [] 
   ,/*History  */ currentMedication: '', allergyHistory: '', breastCancerChoice: '', breastCancerText: '', uterineCancerChoice: '', uterineCancerText: '', ovarianCancerChoice: '', ovarianCancerText: '', colorectalCancerChoice: '', colorectalCancerText: '', otherCancer: '', para: '', gravida: '', pregTest: '', pregnancyHistorys: [], miscarriageEctopics: [], lnmp: '', edd: '', menarche: '', ega: '', durationOfFlow: '', regularity: '', irregularBleeding: '', frequency: '', volume: '', volumeExact: '', dysmemorrhoea: '', dysmemorrhoeaNotes: '', pms: '', pmsNotes: '', contraceptives: [], dyspareunia: '', dyspareuniaNotes: '', dyspareuniaYes: '', dyspareuniaYesNotes: '', cervicalCancerScreenings: [], breastCancerScreenings: [], menopauses: [], violence: '', otherViolence: '', mentalHealth: '', alcoholChoice: '', alcoholText: '', smokesChoice: '', smokesText: '', drugsChoice: '', drugsText: ''
, churchChoice: '', churchText: '', bloodTransfusionChoice: '', bloodTransfusionText: '', partnerAlcoholChoice: '', partnerAlcoholText: '', partnerSmokesChoice: '', partnerSmokesText: '', partnerDrugsChoice: '', partnerDrugsText: '', partnerChurchChoice: '', partnerChurchText: '', socialHistoryTNotes: '', diabetesChoice: '', diabetesText: '', epilepsyChoice: '', epilepsyText: '', asthmaChoice: '', asthmaText: '',rheumaticFeverChoice: '', rheumaticFeverText: '', thyroidDisorderChoice: '', thyroidDisorderText: '', hypertensionChoice: '', hypertensionText: '', pastMedicalNotes: '', surgeries: [], previousHospitalAdmissions: [], postNatalMothers: [], postNatalBabys: [], twins: '',diabetes: '', hypertension: ''
   , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: ''
    , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0 , cashOnlyDs: 0, cashOnly: 0, showButton: true}
    componentDidMount() {

    }
    openNurseAction(visitId) {
        this.setState({barcode: '', billId: '', speciality: '', companyId: '', locationId: '', rate: 0, institutionId: '', pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: true, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false, billCategory: '', fileData: '', results: [], locations: [], options:[], services: [], billItems: [], treatments: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', bedDetails: '', nurseNotes: '', vitals: [], notes: [], referralLetters: [], oldPatient: '', managementLists: [], historyVisits: [], visitId: visitId, msg: '', error: '', diagnosisText: '', patientDiagnosis: []
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: ''
        , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0, cashOnlyDs: 0, cashOnly: 0, showButton: true})
        axios.get(localStorage.getItem('host') + '/patientmanagement/opdmaternity/' + visitId+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data.services)
                    {
                        options.push({value: res.data.services[i].id, label: res.data.services[i].name})
                    }  
                this.setState({ billId: res.data.billId, speciality: res.data.speciality, companyId: res.data.companyId, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billCategory: res.data.billCategory, results: res.data.results,locations: res.data.locations, services: res.data.services, options: options, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, employer: res.data.employer, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, bedDetails: res.data.bedDetails, nurseNotes: res.data.nurseNotes, vitals: res.data.vitals, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementLists: res.data.managementLists, historyVisits: res.data.historyVisits, diagnosisText: res.data.diagnosisText, patientDiagnosis: res.data.patientDiagnosis
                    , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable, drugAllergy: res.data.drugAllergy, otherAllergy: res.data.otherAllergy, medicationGivens: res.data.medicationGivens
                    , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid
                    , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount
                    ,currentMedication: res.data.currentMedication, allergyHistory: res.data.allergyHistory, breastCancerChoice: res.data.breastCancerChoice, breastCancerText: res.data.breastCancerText, uterineCancerChoice: res.data.uterineCancerChoice, uterineCancerText: res.data.uterineCancerText, ovarianCancerChoice: res.data.ovarianCancerChoice, ovarianCancerText: res.data.ovarianCancerText, colorectalCancerChoice: res.data.colorectalCancerChoice, colorectalCancerText: res.data.colorectalCancerText, otherCancer: res.data.otherCancer, para: res.data.para, gravida: res.data.gravida, pregTest: res.data.pregTest, pregnancyHistorys: res.data.pregnancyHistorys, miscarriageEctopics: res.data.miscarriageEctopics, lnmp: res.data.lnmp, edd: res.data.edd, menarche: res.data.menarche, ega: res.data.ega, durationOfFlow: res.data.durationOfFlow, regularity: res.data.regularity, irregularBleeding: res.data.irregularBleeding, frequency: res.data.frequency, volume: res.data.volume, volumeExact: res.data.volumeExact, dysmemorrhoea: res.data.dysmemorrhoea, dysmemorrhoeaNotes: res.data.dysmemorrhoeaNotes, pms: res.data.pms, pmsNotes: res.data.pmsNotes, contraceptives: res.data.contraceptives, dyspareunia: res.data.dyspareunia, dyspareuniaNotes: res.data.dyspareuniaNotes, dyspareuniaYes: res.data.dyspareuniaYes, dyspareuniaYesNotes: res.data.dyspareuniaYesNotes, cervicalCancerScreenings: res.data.cervicalCancerScreenings, breastCancerScreenings: res.data.breastCancerScreenings, menopauses: res.data.menopauses, violence: res.data.violence, otherViolence: res.data.otherViolence, mentalHealth: res.data.mentalHealth, alcoholChoice: res.data.alcoholChoice, alcoholText: res.data.alcoholText, smokesChoice: res.data.smokesChoice, smokesText: res.data.smokesText, drugsChoice: res.data.drugsChoice, drugsText: res.data.drugsText
, churchChoice: res.data.churchChoice, churchText: res.data.churchText, bloodTransfusionChoice: res.data.bloodTransfusionChoice, bloodTransfusionText: res.data.bloodTransfusionText, partnerAlcoholChoice: res.data.partnerAlcoholChoice, partnerAlcoholText: res.data.partnerAlcoholText, partnerSmokesChoice: res.data.partnerSmokesChoice, partnerSmokesText: res.data.partnerSmokesText, partnerDrugsChoice: res.data.partnerDrugsChoice, partnerDrugsText: res.data.partnerDrugsText, partnerChurchChoice: res.data.partnerChurchChoice, partnerChurchText: res.data.partnerChurchText, socialHistoryTNotes: res.data.socialHistoryTNotes, diabetesChoice: res.data.diabetesChoice, diabetesText: res.data.diabetesText, epilepsyChoice: res.data.epilepsyChoice, epilepsyText: res.data.epilepsyText, asthmaChoice: res.data.asthmaChoice, asthmaText: res.data.asthmaText,rheumaticFeverChoice: res.data.rheumaticFeverChoice, rheumaticFeverText: res.data.rheumaticFeverText, thyroidDisorderChoice: res.data.thyroidDisorderChoice, thyroidDisorderText: res.data.thyroidDisorderText, hypertensionChoice: res.data.hypertensionChoice, hypertensionText: res.data.hypertensionText, pastMedicalNotes: res.data.pastMedicalNotes, surgeries: res.data.surgeries, previousHospitalAdmissions: res.data.previousHospitalAdmissions, postNatalMothers: res.data.postNatalMothers, postNatalBabys: res.data.postNatalBabys, twins: res.data.twins,diabetes: res.data.diabetes, hypertension: res.data.hypertension
                   });
                this.props.setLoader(false)
                this.doValidate();
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.setState({showButton: false})
        this.props.setLoader(true)
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = { visitId: this.state.visitId, weight: this.state.weight, height: this.state.height, drugAllergy: this.state.drugAllergy, otherAllergy: this.state.otherAllergy, muac: this.state.muac, bmi: this.state.bmi, bedDetails: this.state.bedDetails, nurseNotes: this.state.nurseNotes, vitals: this.state.vitals, medicationGivens: this.state.medicationGivens
            
            , billItems: billItems, billId: this.state.billId, speciality: this.state.speciality
            , currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount
            , currentMedication: this.state.currentMedication, allergyHistory: this.state.allergyHistory, breastCancerChoice: this.state.breastCancerChoice, breastCancerText: this.state.breastCancerText, uterineCancerChoice: this.state.uterineCancerChoice, uterineCancerText: this.state.uterineCancerText, ovarianCancerChoice: this.state.ovarianCancerChoice, ovarianCancerText: this.state.ovarianCancerText, colorectalCancerChoice: this.state.colorectalCancerChoice, colorectalCancerText: this.state.colorectalCancerText, otherCancer: this.state.otherCancer, para: this.state.para, gravida: this.state.gravida, pregTest: this.state.pregTest, pregnancyHistorys: this.state.pregnancyHistorys, miscarriageEctopics: this.state.miscarriageEctopics, lnmp: this.state.lnmp, edd: this.state.edd, menarche: this.state.menarche, ega: this.state.ega, durationOfFlow: this.state.durationOfFlow, regularity: this.state.regularity, irregularBleeding: this.state.irregularBleeding, frequency: this.state.frequency, volume: this.state.volume, volumeExact: this.state.volumeExact, dysmemorrhoea: this.state.dysmemorrhoea, dysmemorrhoeaNotes: this.state.dysmemorrhoeaNotes, pms: this.state.pms, pmsNotes: this.state.pmsNotes, contraceptives: this.state.contraceptives, dyspareunia: this.state.dyspareunia, dyspareuniaNotes: this.state.dyspareuniaNotes, dyspareuniaYes: this.state.dyspareuniaYes, dyspareuniaYesNotes: this.state.dyspareuniaYesNotes, cervicalCancerScreenings: this.state.cervicalCancerScreenings, breastCancerScreenings: this.state.breastCancerScreenings, menopauses: this.state.menopauses, violence: this.state.violence, otherViolence: this.state.otherViolence, mentalHealth: this.state.mentalHealth, alcoholChoice: this.state.alcoholChoice, alcoholText: this.state.alcoholText, smokesChoice: this.state.smokesChoice, smokesText: this.state.smokesText, drugsChoice: this.state.drugsChoice, drugsText: this.state.drugsText
, churchChoice: this.state.churchChoice, churchText: this.state.churchText, bloodTransfusionChoice: this.state.bloodTransfusionChoice, bloodTransfusionText: this.state.bloodTransfusionText, partnerAlcoholChoice: this.state.partnerAlcoholChoice, partnerAlcoholText: this.state.partnerAlcoholText, partnerSmokesChoice: this.state.partnerSmokesChoice, partnerSmokesText: this.state.partnerSmokesText, partnerDrugsChoice: this.state.partnerDrugsChoice, partnerDrugsText: this.state.partnerDrugsText, partnerChurchChoice: this.state.partnerChurchChoice, partnerChurchText: this.state.partnerChurchText, socialHistoryTNotes: this.state.socialHistoryTNotes, diabetesChoice: this.state.diabetesChoice, diabetesText: this.state.diabetesText, epilepsyChoice: this.state.epilepsyChoice, epilepsyText: this.state.epilepsyText, asthmaChoice: this.state.asthmaChoice, asthmaText: this.state.asthmaText,rheumaticFeverChoice: this.state.rheumaticFeverChoice, rheumaticFeverText: this.state.rheumaticFeverText, thyroidDisorderChoice: this.state.thyroidDisorderChoice, thyroidDisorderText: this.state.thyroidDisorderText, hypertensionChoice: this.state.hypertensionChoice, hypertensionText: this.state.hypertensionText, pastMedicalNotes: this.state.pastMedicalNotes, surgeries: this.state.surgeries, previousHospitalAdmissions: this.state.previousHospitalAdmissions, postNatalMothers: this.state.postNatalMothers, postNatalBabys: this.state.postNatalBabys, twins: this.state.twins,diabetes: this.state.diabetes, hypertension: this.state.hypertension}
        axios.post(localStorage.getItem('host') + '/patientmanagement/opdmaternitysave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data, showButton: true }); 
                document.getElementById('topDiv').scrollIntoView()
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err , showButton: true})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('nurseAction').style = 'display:none'
        this.props.getList();
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : ''
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                value = value > 0 && value < 250 ? value : ''
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi })
                break;
            default:
               console.log("")
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : '';
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 180 ? value : '';
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : '';
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 300 ? value : '';
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : '';
                break;
            case "gcs":
                list[index].gcs = value > 0 && value < 16 ? value : '';
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : '';
                break;

        }
        this.setState({ vitals: list })
    }
    setMedicationGivenValue(index, name, value) {
        var list = this.state.medicationGivens;
        switch (name) {
            case "drugName":
                list[index].drugName = value;
                break;
            case "route":
                list[index].route = value;
                break;
            case "dose":
                list[index].dose = value;
                break;
            default:
                list[index].time = value;
                break;

        }
        this.setState({ medicationGivens: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature:'', pulse: '', glucometer:'', systolic: '', diastolic:'', spO2: '' });
        this.setState({ vitals: list })
    }
    addMedicationGiven() {
        let list = this.state.medicationGivens.slice();
        const now = new Date();
        list.push({ time: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), drugName:'', route: '', dose:'', sign: localStorage.getItem('fullname') });
        this.setState({ medicationGivens: list })
    }
    deleteRow(index)
     {
        let list = this.state.vitals.slice();
        list.splice(index, 1); 
        this.setState({ vitals: list }) 
     }
     deleteRowMedicationGiven(index)
      {
         let list = this.state.medicationGivens.slice();
         list.splice(index, 1); 
         this.setState({ medicationGivens: list }) 
      }
    showNurseHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/opdmaternityhistory/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction1').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction1').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    viewResults() {
        document.getElementById('resultPopup1').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup1').style = 'display:none'
    }
    toggleMainTabs(name) {
        switch (name) {
            case "billingDetails":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: true, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "postNatalT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: true, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "socialHistoryT":
                this.setState({ pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: true, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "familyHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: true, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "surgicalHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: true, medicalHistoryT: false, mentalHealthT: false})
                break
            case "medicalHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: true, mentalHealthT: false})
                break
            case "gynaecologicalHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: true, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "obstetricHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: true, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "pastMedicalHistoryT":
                this.setState({pastMedicalHistoryT:true ,currentMedicationT:false ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "currentMedicationT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:true ,allergyHistoryT:false ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false})
                break
            case "allergyHistoryT":
                this.setState({pastMedicalHistoryT:false ,currentMedicationT:false ,allergyHistoryT:true ,obstetricHistoryT: false, gynaecologicalHistoryT: false, billingDetails: false, gynae: false, postNatalT: false, socialHistoryT: false, familyHistoryT: false, surgicalHistoryT: false, medicalHistoryT: false, mentalHealthT: false })
                break    
            default:
                this.setState({nurseDetails: true, billingDetails: false})
                break

        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }

    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
            {
             s.cashOnly = 1;
            }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: this.getMargin(ss.fixedCost, ss.margin, 'MIN'), max: this.getMargin(ss.fixedCost, ss.margin, 'MAX'), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                }
            this.setState({ billItems: list });
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
            this.doValidate()
           }
       }
       getMargin(unitCost, margin, type)
          {
           let val = 0
           if(type === 'MIN')
               {
                 if(unitCost < 0)
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));  
                   }  
                else
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));    
                   val = val < 0?0:val
                   }   
               }
           else{
               if(unitCost < 0)
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));  
                   val = val > 0?0:val
                   }  
                else
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));    
                   }   
               
               }    
   
          return val;    
          }    
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                if(list[index].billItemType === 'PRODUCT')
                {
                  if(Number(list[index].quantity) > Number(value))
                     {
                        list[index].quantity = value  
                     }
                }
                else
                  {
                    list[index].quantity = value
                    this.calcQuantity(index, list[index].quantity)
                  }
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "cashOnly":
                list[index].cashOnly = Number(value)
                this.calcQuantity(index, list[index].quantity)
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })    
      this.doValidate() 
       }
       calcBill(list, authorisationNumber, authorisationAmount)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
            {
                cashPayable += Number(insurancePayable) - Number(authorisationAmount);
                insurancePayable = authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
        calcQuantity(index, value)
        {
            var list = this.state.billItems;  
            list[index].cost = Number(list[index].unitCost) * Number(value);
            list[index].totalCost = list[index].cost;
            this.setState({ billItems: list })  
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
        }
          hideEntry(index)
           {
              let list = this.state.billItems.slice();
              list[index].activeStatus = 0
              this.setState({ billItems: list }); 
              this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
           } 
           getProductItems() {
               this.setState({ productItems: [] });
               this.props.setLoader(true)
               var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
               axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitems', data, {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                        } 
                    this.setState({productItems: res.data, productOptions: options});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )
           }
        addProductItem()
           {
            if(this.state.productItemLocationId.value > 0)
               {  console.log('......... service cost id '+this.state.productItemLocationId.value) 
                const now = new Date();
                var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                let list = this.state.billItems.slice();
                var s = '';
                for (var i in this.state.productItems) {
                    if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                        s = this.state.productItems[i];
                        s.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: s.cashOnly;
                    }
                }
             if(this.state.quantity <= s.quantity)
                 {   
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
                this.setState({ billItems: list, cashOnlyDs: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
                 }
               else
                  {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                  }  
               }
           } 
           doValidate() {
               setTimeout(() => {
                   this.validate();
               }, 1000);
           }
           validate() {
       
               var error = false;
               var billItems = this.state.billItems;
               if (billItems === null) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else if (billItems.length === 0) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else {
                  
                   
                   for(var i in billItems)
                   {
                    billItems[i].unitCostError =  "" 
                    if(billItems[i].allowEdit)
                      {
                       if (billItems[i].unitCost === null) {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost === "") {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost >= 0 && billItems[i].unitCost > billItems[i].max) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost > 0) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than 0"
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                       else {
                           billItems[i].unitCostError =  "" 
                       }
       
                       console.log(billItems[i].unitCostError)
                      }
                 this.setState({ billItemsError: "", billItems: billItems })   
                   }
               }
               console.log(" error" + error)
               if(this.state.allowSave === false)
                 {
                   this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
                 }
               else  if (error === true) {
                   this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
               }
               else {
                   this.setState({ error: "", showSubmit: true })
               }
           } 
           doProductItemFromBarCode(val) {
               this.props.setLoader(true)
               setTimeout(() => {
                   if(this.state.barcode === val && this.state.barcode !== "")
                     {
                      this.setProductItemFromBarCode();
                     }
               }, 2000);
           }
           setProductItemFromBarCode()
                 {
                   const now = new Date();
                    var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                    var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, barcode: this.state.barcode, rate: this.state.rate, billCategory: this.state.billCategory}
                    axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitemsbarcode', data, {
                       headers: {
                           'Authorization': `Bearer ${localStorage.getItem('token')}`
                       }
                   }).then(
                       res => {
                           this.setState({barcode: ""})
                           if(res.data.quantity > 0)
                             {
                           var quantity = 0;
                           var index = 0;
                           var list = this.state.billItems;
                           for(var i in list)
                              {
                               if(list[i].productItemLocationId === res.data.productItemLocationId)
                                   {
                                    quantity++;
                                    index = i;
                                   }
                              }
                           if(quantity === 0)
                              {
                                res.data.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: res.data.cashOnly;
                                list.push({id: 0, serviceId: 0, name: res.data.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:res.data.type
                                , tariffCode: res.data.tariffCode, billStartDate: date1, billEndDate: date1, quantity: 1, unitCost: res.data.sellingPrice, min: this.getMargin(res.data.sellingPrice, res.data.margin, 'MIN'), max: this.getMargin(res.data.sellingPrice, res.data.margin, 'MAX'), cost: res.data.sellingPrice , totalCost: res.data.sellingPrice, cashOnly: res.data.cashOnly, allowEdit: res.data.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: res.data.authoAmount, wac: 0.0, lastReceiptCost: res.data.buyingPrice, activeStatus: 1, productItemId: res.data.id, productItemLocationId: res.data.productItemLocationId, locationId: this.state.locationId});
                                this.setState({ billItems: list , cashOnlyDs: 0});
                                 this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                               this.doValidate()
                              }   
                           else if(quantity <= res.data.quantity)
                           {   
                           list[index].quantity = list[index].quantity + 1
                           list[index].cost =  list[index].quantity * list[index].unitCost
                           list[index].totalCost =  list[index].quantity * list[index].unitCost
                          this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                          this.doValidate()
                           }
                         else
                            {                              
                              document.getElementById('topDiv').scrollIntoView()
                              this.setState({error:"Entered quantity cannot be greater than available quantity of "+res.data.quantity})  
                            }
                          
                             } 
                          else
                             {
                                document.getElementById('topDiv').scrollIntoView()
                              this.setState({error: "Product was not found."}) 
                             }   
                             this.props.setLoader(false)
                       }, err => {
                           console.log(".....")
                           this.setState({error: err+"", barcode: ""})
                           document.getElementById('topDiv').scrollIntoView()
                           this.props.setLoader(false)
                       }
                   )
                   
                 } 
           processDateTime(date)
             {
               const now = new Date(date);
               const todayY = now.getFullYear();
               const todayM = checkZero(now.getMonth() + 1);
               const todayD = checkZero(now.getDate());
               var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
               console.log(date1)
              return date1;
             }
    getPreConfigured(locationId)
        {
            this.setState({ procedures: [], locationId: locationId });
               this.props.setLoader(true)
              axios.get(localStorage.getItem('host') + '/patientmanagement/getprocedures/'+locationId+"/"+this.state.visitId,  {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    this.setState({procedures: res.data});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )  
        }    
        viewHistory(visitId) {
    
            this.setState({ htmlText: '', popupName: '' })
             this.props.setLoader(true)
            axios.get(localStorage.getItem('host') + '/patientmanagement/opdmaternityhistory/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                    document.getElementById('genericPopup').style = 'display:block'
                    this.props.setLoader(false)
                }, err => {
                    console.log(".....")
                    this.setState({ error: err })
                    this.props.setLoader(false)
                }
            )
        }
  addPreconfigs()
        {
         if(this.state.procedure >= 0)
            {
             var procedures = this.state.procedures[this.state.procedure];
             let list = this.state.billItems.slice();
             for (var i in procedures.productItems) {
                var s = procedures.productItems[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * s.quantity), totalCost: (s.sellingPrice * s.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
               }
               for (i in procedures.services) {
                s = procedures.services[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                 }
           
                this.setState({ billItems: list , cashOnlyPcp: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
            }
        } 
        
     addPregnancyHistoryTable()
     {
        let list = this.state.pregnancyHistorys.slice();
        list.push({ id: 0, dob: '', gender: '', modeOfDelivery: '', modNotes: '', antenatalComplications: '', deliveryComplications: '', postpartumComplications: '', outcome: '', deliveryAge: '' });
        this.setState({ pregnancyHistorys: list });
     }
     deletePregnancyHistoryRow(index)
      {
         let list = this.state.pregnancyHistorys.slice();
         list.splice(index, 1); 
         this.setState({ pregnancyHistorys: list }) 
      }
     setPregnancyHistory(index, name, value) {
        let list = this.state.pregnancyHistorys.slice();
        switch (name) {
            case "dob":
                list[index].dob = value
                break;
            case "gender":
                list[index].gender = value
                break;
            case "modeOfDelivery":
                list[index].modeOfDelivery = value
                break;
            case "modNotes":
                list[index].modNotes = value
                break;
            case "antenatalComplications":
                list[index].antenatalComplications = value
                break;
            case "deliveryComplications":
                list[index].deliveryComplications = value
                break;
            case "postpartumComplications":
                list[index].postpartumComplications = value
                break;
            case "outcome":
                list[index].outcome = value
                break;
            case "deliveryAge":
                list[index].deliveryAge = value
                break;
            case "antenatalComplicationsRemarks":
                list[index].antenatalComplicationsRemarks = value
                break;
            case "outcomeRemarks":
                list[index].outcomeRemarks = value
                break;
            case "deliveryAgeRemarks":
                list[index].deliveryAgeRemarks = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ pregnancyHistorys: list });
    }
     addContraceptivesTable()
     {
        let list = this.state.contraceptives.slice();
        list.push({ id: 0, name: '', startDate: '', endDate: '', notes: '' });
        this.setState({ contraceptives: list });
     }
     deleteContraceptivesRow(index)
      {
         let list = this.state.contraceptives.slice();
         list.splice(index, 1); 
         this.setState({ contraceptives: list }) 
      }
     setContraceptive(index, name, value) {
        let list = this.state.contraceptives.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            case "endDate":
                list[index].endDate = value
                break;
            default:
                list[index].notes = value
                break;
        }
        this.setState({ contraceptives: list });
    }
     addCervicalCancerScreeningTable()
     {
        let list = this.state.cervicalCancerScreenings.slice();
        list.push({ id: 0, year: '', type: '', result: '' });
        this.setState({ cervicalCancerScreenings: list });
     }
     deleteCervicalCancerScreeningRow(index)
      {
         let list = this.state.cervicalCancerScreenings.slice();
         list.splice(index, 1); 
         this.setState({ cervicalCancerScreenings: list }) 
      }
     setCervicalCancerScreening(index, name, value) {
        let list = this.state.cervicalCancerScreenings.slice();
        switch (name) {
            case "year":
                list[index].year = value
                break;
            case "month":
                list[index].month = value
                break;
            case "type":
                list[index].type = value
                break;
            case "result":
                list[index].result = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ cervicalCancerScreenings: list });
    }
     addBreastCancerScreeningTable()
     {
        let list = this.state.breastCancerScreenings.slice();
        list.push({ id: 0, year: '', month: '', selfExam: '', clinicalExam: '', mammogram: '', breastUltrasoundScan: '' });
        this.setState({ breastCancerScreenings: list });
     }
     deleteBreastCancerScreeningRow(index)
      {
         let list = this.state.breastCancerScreenings.slice();
         list.splice(index, 1); 
         this.setState({ breastCancerScreenings: list }) 
      }
     setBreastCancerScreening(index, name, value) {
        let list = this.state.breastCancerScreenings.slice();
        switch (name) {
            case "year":
                list[index].year = value
                break;
            case "month":
                list[index].month = value
                break;
            case "selfExam":
                list[index].selfExam = value
                break;
            case "clinicalExam":
                list[index].clinicalExam = value
                break;
            case "mammogram":
                list[index].mammogram = value
                break;
            case "breastUltrasoundScan":
                list[index].breastUltrasoundScan = value
                break;
            case "selfExamRemarks":
                list[index].selfExamRemarks = value
                break;
            case "clinicalExamRemarks":
                list[index].clinicalExamRemarks = value
                break;
            case "breastUltrasoundScanRemarks":
                list[index].breastUltrasoundScanRemarks = value
                break;
            case "mammogramRemarks":
                list[index].mammogramRemarks = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ breastCancerScreenings: list });
    }
     addMenopauseTable()
     {
        let list = this.state.menopauses.slice();
        list.push({ id: 0, symptoms: '', other: '', activeStatus: 1 });
        this.setState({ menopauses: list });
     }
     deleteMenopauseRow(index)
      {
         let list = this.state.menopauses.slice();
         list.splice(index, 1); 
         this.setState({ menopauses: list }) 
      }
     setMenopause(index, name, value) {
        let list = this.state.menopauses.slice();
        switch (name) {
            case "symptoms":
                list[index].symptoms = value
                break;
            case "other":
                list[index].other = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ menopauses: list });
    }
     addSurgeriesTable()
     {
        let list = this.state.surgeries.slice();
        list.push({ id: 0, year: '', surgery: '', type: '', hospital: '', surgeon: '', complications:'' });
        this.setState({ surgeries: list });
     }
     deleteSurgeriesRow(index)
      {
         let list = this.state.surgeries.slice();
         list.splice(index, 1); 
         this.setState({ surgeries: list }) 
      }
     setSurgery(index, name, value) {
        let list = this.state.surgeries.slice();
        switch (name) {
            case "year":
                list[index].year = value
                break;
            case "surgery":
                list[index].surgery = value
                break;
            case "type":
                list[index].type = value
                break;
            case "hospital":
                list[index].hospital = value
                break;
            case "surgeon":
                list[index].surgeon = value
                break;
            case "complications":
                list[index].complications = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ surgeries: list });
    }
     addMiscarriageEctopicTable()
     {
        let list = this.state.miscarriageEctopics.slice();
        list.push({ id: 0, year: '', type: '', ectopicManagement: '', medicalManagementDrugUsed: '', evacuation: '', molarPregnancyType: '', molarManagement: '', notes: '' });
        this.setState({ miscarriageEctopics: list });
     }
     deleteMiscarriageEctopicRow(index)
      {
         let list = this.state.miscarriageEctopics.slice();
         list.splice(index, 1); 
         this.setState({ miscarriageEctopics: list }) 
      }
     setMiscarriageEctopic(index, name, value) {
        let list = this.state.miscarriageEctopics.slice();
        switch (name) {
            case "year":
                list[index].year = value
                break;
            case "type":
                list[index].type = value
                break;
            case "ectopicManagement":
                list[index].ectopicManagement = value
                break;
            case "ectopicManagementRemarks":
                list[index].ectopicManagementRemarks = value
                break;
            case "medicalManagementDrugUsed":
                list[index].medicalManagementDrugUsed = value
                break;
            case "evacuation":
                list[index].evacuation = value
                break;
            case "molarPregnancyType":
                list[index].molarPregnancyType = value
                break;
            case "molarManagement":
                list[index].molarManagement = value
                break;
            case "notes":
                list[index].notes = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ miscarriageEctopics: list });
    }
    addPreviousHospitalAdmission()
    {
       let list = this.state.previousHospitalAdmissions.slice();
       list.push({ id: 0, year: '', reason: '', activeStatus: 1 });
       this.setState({ previousHospitalAdmissions: list });
    }
    deletePreviousHospitalAdmissionRow(index)
     {
        let list = this.state.previousHospitalAdmissions.slice();
        list.splice(index, 1); 
        this.setState({ previousHospitalAdmissions: list }) 
     }
    setPreviousHospitalAdmission(index, name, value) {
       let list = this.state.previousHospitalAdmissions.slice();
       switch (name) {
           case "year":
               list[index].year = value
               break;
           case "reason":
               list[index].reason = value
               break;
           default:
               list[index].repeats = value
               break;
       }
       this.setState({ previousHospitalAdmissions: list });
   }        
   addPostNatalMotherTable()
   {
      let list = this.state.postNatalMothers.slice();
      list.push({ id: 0, date: '', type: '', time: '', bloodPressure: '', pulse: '', resps: '', temp: '', pvBleeding: '', uterus: '', bladder: '' });
      this.setState({ postNatalMothers: list });
   }
   deletePostNatalMotherRow(index)
    {
       let list = this.state.postNatalMothers.slice();
       list.splice(index, 1); 
       this.setState({ postNatalMothers: list }) 
    }
   setPostNatalMother(index, name, value) {
      let list = this.state.postNatalMothers.slice();
      switch (name) {
          case "type":
              list[index].type = value
              break;
          case "date":
              list[index].date = value
              break;
          case "time":
              list[index].time = value
              break;
          case "bloodPressure":
              list[index].bloodPressure = value
              break;
          case "pulse":
              list[index].pulse = value
              break;
          case "resps":
              list[index].resps = value
              break;
          case "temp":
              list[index].temp = value
              break;
          case "pvBleeding":
              list[index].pvBleeding = value
              break;
          case "uterus":
              list[index].uterus = value
              break;
          default:
              list[index].bladder = value
              break;
      }
      this.setState({ postNatalMothers: list });
  }      
  addPostNatalBabyTable()
  {
     let list = this.state.postNatalBabys.slice();
     list.push({ id: 0, date: '', type: '', time: '', temp: '', resps: '', cord: '', skin: '', bfFeeding: '', afFeeding: '', bowelElimination: '', urineElimination: '' });
     this.setState({ postNatalBabys: list });
  }
  deletePostNatalBabyRow(index)
   {
      let list = this.state.postNatalBabys.slice();
      list.splice(index, 1); 
      this.setState({ postNatalBabys: list }) 
   }
  setPostNatalBaby(index, name, value) {
     let list = this.state.postNatalBabys.slice();
     switch (name) {
         case "type":
             list[index].type = value
             break;
         case "date":
             list[index].date = value
             break;
         case "time":
             list[index].time = value
             break;
         case "temp":
             list[index].temp = value
             break;
         case "resps":
             list[index].resps = value
             break;
         case "cord":
             list[index].cord = value
             break;
         case "skin":
             list[index].skin = value
             break;
         case "bfFeeding":
             list[index].bfFeeding = value
             break;
         case "afFeeding":
             list[index].afFeeding = value
             break;
         case "bowelElimination":
             list[index].bowelElimination = value
             break;
         default:
             list[index].urineElimination = value
             break;
     }
     this.setState({ postNatalBabys: list });
 }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="nurseAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Nurse Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="resultPopup1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="PDF File View" src={'data:application/pdf;base64,' + this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.showNurseHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green">
                            <div className="card-header  text-dark">Nurse Notes</div>
                            <div className="card-body">
                                <ul>
                                <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current Notes</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.results.length > 0 && (<div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Referral Letter</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('REFERRAL_LETTERS', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Employer:</span> {this.state.employer}</div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    </div>
                            </div>
                        </div>
                        <div id="110"></div>
                        <br />

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                           <li className="nav-item"><span className={this.state.obstetricHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("obstetricHistoryT") }}>Obstetric History</span></li>
                                            <li className="nav-item"><span className={this.state.gynaecologicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("gynaecologicalHistoryT") }}>Gynaecological History</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("pastMedicalHistoryT") }}>Past Medical Surgical History</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("familyHistoryT") }}>Family History</span></li>
                                             <li className="nav-item"><span className={this.state.socialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("socialHistoryT") }}>Social History</span></li>
                                            <li className="nav-item"><span className={this.state.currentMedicationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("currentMedicationT") }}>Current Medication</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("allergyHistoryT") }}>Allergy History</span></li>
                                        <li className="nav-item"><span className={this.state.postNatalT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("postNatalT") }}>Post Natal</span></li>
                           <li className="nav-item">
                                <a className={this.state.billingDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("billingDetails") }} href={false}>Billing</a>
                            </li>
                        </ul>
                        <div className="tab-content pt-2 pl-1" id="pills-tabContent">
                                            <div className={this.state.currentMedicationT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                       <textarea className="form-control" value={this.state.currentMedication} onChange={e => { this.setState({ currentMedication: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.allergyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                     <textarea className="form-control" value={this.state.allergyHistory} onChange={e => { this.setState({ allergyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Twins</span><input type="text" value={this.state.twins} onChange={e => this.setState({ twins: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Diabetes</span><input type="text" value={this.state.diabetes} onChange={e => this.setState({ diabetes: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Hypertension</span><input type="text" value={this.state.hypertension} onChange={e => this.setState({ hypertension: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                             <div className="card card-green">
                                             <div className="card-header"> Gynaecological Malignancy</div>
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.breastCancerChoice === "1"} onChange={e => this.setState({ breastCancerChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Breast cancer</span><input type="text" value={this.state.breastCancerText} onChange={e => this.setState({ breastCancerText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.uterineCancerChoice === "1"} onChange={e => this.setState({ uterineCancerChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Uterine cancer</span><input type="text" value={this.state.uterineCancerText} onChange={e => this.setState({ uterineCancerText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.ovarianCancerChoice === "1"} onChange={e => this.setState({ ovarianCancerChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Ovarian cancer</span><input type="text" value={this.state.ovarianCancerText} onChange={e => this.setState({ ovarianCancerText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.colorectalCancerChoice === "1"} onChange={e => this.setState({ colorectalCancerChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Colorectal cancer</span><input type="text" value={this.state.colorectalCancerText} onChange={e => this.setState({ colorectalCancerText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.otherCancer} onChange={e => this.setState({ otherCancer: e.target.value })} className="form-control"   ></textarea></div></div>
                                              
                                                </div>
                                                </div></div>
                                         </div>
                                            <div className={this.state.obstetricHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>

                                            <div className="card card-green">
                                                <div className="card-header">General Info</div>
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Para</span><input type="text" value={this.state.para} onChange={e => this.setState({ para: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Gravida</span><input type="text" value={this.state.gravida} onChange={e => this.setState({ gravida: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Preg Test</span><select className="form-control" value={this.state.pregTest} onChange={e =>{ this.setState({ pregTest: e.target.value }); this.calculateEDDEGA(this.state.lnmp, e.target.value)}} >
                                            <option value="">Choose</option>
                                            <option value="Positive">Positive</option>
                                            <option value="Negative">Negative</option>
                                            <option value="Indeterminate">Indeterminate</option>
                                            </select></div></div>
                                                   </div>
                                                </div></div>
                                            <div className="card card-green">
                                                <div className="card-header">Pregnancy History</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>#</th>
                                                       <th>DOB</th>
                                                       <th>Gender</th>
                                                       <th>Mode of Delivery</th>
                                                       <th>MOD Notes</th>
                                                       <th>Antenatal Complications</th>
                                                       <th>Delivery Complications</th>
                                                       <th>Postpartum Complications</th>
                                                       <th>Outcome</th>
                                                       <th>Delivery Age</th>
                                                       <th></th>
                                                   </tr>
                                                   <tbody>
                                                   {this.state.pregnancyHistorys.map((item, index) => (
                                                       <tr key={index}>
                                                        <td>{index + 1}</td>
                                                           <td><input type="text" className="form-control" value={item.dob} onChange={e => { this.setPregnancyHistory(index, 'dob', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.gender} onChange={e => { this.setPregnancyHistory(index, 'gender', e.target.value); }}>
                                                           <option value="">Choose</option>
                                                               <option value="Male">Male</option>
                                                               <option value="Female">Female</option>
                                                               <option value="Other">Other</option>
                                                               </select></td>
                                                           <td><select className="form-control" value={item.modeOfDelivery} onChange={e => { this.setPregnancyHistory(index, 'modeOfDelivery', e.target.value); }}>
                                                           <option value="">Choose</option>
                                                               <option value="NVD">NVD</option>
                                                               <option value="NVD Episiotomy">NVD Episiotomy</option>
                                                               <option value="NVD Tear">NVD Tear</option>
                                                               <option value="Assisted VD - Vacuum">Assisted VD - Vacuum</option>
                                                               <option value="Assisted VD - FORCEPs">Assisted VD - FORCEPs</option>
                                                               <option value="LSCS Elective">LSCS Elective</option>
                                                               <option value="LSCS Emergency">LSCS Emergency</option>
                                                               </select></td>
                                                           <td><input type="text" className="form-control" value={item.modNotes} onChange={e => { this.setPregnancyHistory(index, 'modNotes', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.antenatalComplications} onChange={e => { this.setPregnancyHistory(index, 'antenatalComplications', e.target.value); }}>
                                                           <option value="">Choose</option>
                                                               <option value="PIH">PIH</option>
                                                               <option value="GDM">GDM</option>
                                                               <option value="Rhesus negative">Rhesus negative</option>
                                                               <option value="FGR">FGR</option>
                                                               <option value="PTB">PTB</option>
                                                               <option value="Other">Other </option>
                                                               </select><input type="text" className="form-control" value={item.antenatalComplicationsRemarks} onChange={e => { this.setPregnancyHistory(index, 'antenatalComplicationsRemarks', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.deliveryComplications} onChange={e => { this.setPregnancyHistory(index, 'deliveryComplications', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.postpartumComplications} onChange={e => { this.setPregnancyHistory(index, 'postpartumComplications', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.outcome} onChange={e => { this.setPregnancyHistory(index, 'outcome', e.target.value); }}>
                                                           <option value="">Choose</option>
                                                               <option value="Alive and Well">Alive & Well</option>
                                                               <option value="Alive But">Alive But</option>
                                                               <option value="Still Born">Still Born</option>
                                                               <option value="ENND">ENND</option>
                                                               <option value="LNND">LNND</option>
                                                               </select><input type="text" className="form-control" value={item.outcomeRemarks} onChange={e => { this.setPregnancyHistory(index, 'outcomeRemarks', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.deliveryAge} onChange={e => { this.setPregnancyHistory(index, 'deliveryAge', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="Pre-term">Pre-term</option>
                                                               <option value="Term">Term</option>
                                                               <option value="Post-term">Post-term</option>
                                                               </select><input type="text" className="form-control" value={item.deliveryAgeRemarks} onChange={e => { this.setPregnancyHistory(index, 'deliveryAgeRemarks', e.target.value); }} /></td> 
                                                               <td ><span className="link-red" onClick={() => this.deletePregnancyHistoryRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                       </tr> ))}
                                                   </tbody>
                                               </table>
                                               </div>
                                                <button onClick={() => this.addPregnancyHistoryTable()} className="btn btn-primary" >Insert New Pregnancy History Line</button>
                                               </div></div>
                                               <div className="card card-green">
                                                <div className="card-header">Miscarriage / Ectopic</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>#</th>
                                                       <th>Year</th>
                                                       <th>Type</th>
                                                       <th>Ectopic Management</th>
                                                       <th>Medical Management Drug Used</th>
                                                       <th>Evacuation</th>
                                                       <th>Molar Pregnancy Type</th>
                                                       <th>Molar Management</th>
                                                       <th>Notes</th>
                                                       <th></th>
                                                   </tr>
                                                   <tbody>
                                                   {this.state.miscarriageEctopics.map((item, index) => (
                                                       <tr key={index}>
                                                       <td>{index + 1}</td>
                                                           <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setMiscarriageEctopic(index, 'year', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.type} onChange={e => { this.setMiscarriageEctopic(index, 'type', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="Miscarriage">Miscarriage</option>
                                                               <option value="Ectopic">Ectopic</option>
                                                               <option value="Molar">Molar</option>
                                                               </select></td>
                                                           <td><select className="form-control" value={item.ectopicManagement} onChange={e => { this.setMiscarriageEctopic(index, 'ectopicManagement', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="Medical">Medical</option>
                                                               <option value="Salpingotomy - laparoscopic">Salpingectomy - laparoscopic</option>
                                                               <option value="Salpingotomy - laparotomy">Salpingectomy - laparotomy</option>
                                                               <option value="Salpingotomy">Salpingotomy - laparoscopic</option>
                                                               <option value="Salpingotomy">Salpingotomy - laparotomy</option>
                                                               <option value="Salpingotomy">Salpingostomy - laparoscopic</option>
                                                               <option value="Salpingotomy">Salpingostomy - laparotomy</option>
                                                               <option value="Other">Other</option>
                                                               </select><input type="text" className="form-control" value={item.ectopicManagementRemarks} onChange={e => { this.setMiscarriageEctopic(index, 'ectopicManagementRemarks', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.medicalManagementDrugUsed} onChange={e => { this.setMiscarriageEctopic(index, 'medicalManagementDrugUsed', e.target.value); }} /></td>
                                                           <td><select className="form-control" value={item.evacuation} onChange={e => { this.setMiscarriageEctopic(index, 'evacuation', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="D and C">D & C</option>
                                                               <option value="MVA">MVA</option>
                                                               <option value="Suction">Suction </option>
                                                               <option value="Hysteroscopic">Hysteroscopic</option>
                                                               </select></td>
                                                           <td><select className="form-control" value={item.molarPregnancyType} onChange={e => { this.setMiscarriageEctopic(index, 'molarPregnancyType', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="Complete">Complete</option>
                                                               <option value="Incomplete">Incomplete</option>
                                                               <option value="GTD">GTD </option>
                                                               </select></td>
                                                           <td><select className="form-control" value={item.molarManagement} onChange={e => { this.setMiscarriageEctopic(index, 'molarManagement', e.target.value); }}>
                                                               <option value="">Choose</option>
                                                               <option value="Medical">Medical</option>
                                                               <option value="Surgical">Surgical</option>
                                                               <option value="Other">Other</option>
                                                               </select></td>
                                                           <td><input type="text" className="form-control" value={item.notes} onChange={e => { this.setMiscarriageEctopic(index, 'notes', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteMiscarriageEctopicRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                       </tr>))}
                                                   </tbody>
                                               </table>
                                               </div>
                                                <button onClick={() => this.addMiscarriageEctopicTable()} className="btn btn-primary" >Insert New Miscarriage / Ectopic Line</button>
                                               </div></div>
                                            </div>

                                            <div className={this.state.gynaecologicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> LNMP </span>  <input type="text" className="form-control" value={this.state.lnmp} onChange={e => {this.setState({ lnmp: e.target.value }); this.calculateEDDEGA(e.target.value, this.state.pregTest)}}  />
                                                    <MyDatePicker onChange={(d)=> {
                                                        this.setState({lnmp: d}); this.calculateEDDEGA(d, this.state.pregTest)
                                                    }} />
                                                        </div></div>
                                                        <div className="col"></div>
                                                        <div className="col"><div className="input-group"><span className="input-group-text">EDD</span><input type="text" value={this.state.edd} onChange={e => this.setState({ edd: e.target.value })} className="form-control" /> <MyDatePicker onChange={(d)=> {
                                                       this.setState({edd: d})
                                                    }} /></div></div>
                                                    <div className="col"></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Menarche  </span> <input type="text" value={this.state.menarche} onChange={e => this.setState({ menarche: e.target.value })} className="form-control" /></div></div>
                                               <div className="col"></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">EGA</span><input type="text" value={this.state.ega} onChange={e => this.setState({ ega: e.target.value })} className="form-control" /></div></div>
                                                <div className="col"></div>
                                                </div>
                                                <div className="card card-green">
                                                <div className="card-header">Period</div>
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Duration of Flow</span>  <input type="text" value={this.state.durationOfFlow} onChange={e => this.setState({ durationOfFlow: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Regularity  </span> <select className="form-control" value={this.state.regularity} onChange={e => this.setState({ regularity: e.target.value })}>
                                                               <option value="">Choose</option>
                                                               <option value="Regular">Regular</option>
                                                               <option value="Irregular">Irregular </option>
                                                               </select></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Irregular Bleeding </span><select className="form-control" value={this.state.irregularBleeding} onChange={e => this.setState({ irregularBleeding: e.target.value })}>
                                                               <option value="">Choose</option>
                                                               <option value="18 to 25 yrs: cycle length variance less than 9 days">18 to 25 yrs: cycle length variance &lt; 9 days</option>
                                                               <option value="26 to 41 yrs: cycle length variance less than 7 days">26 to 41 yrs: cycle length variance &lt; 7 days</option>
                                                               <option value="42 to 45 yrs: cycle length variance less than 9 days">42 to 45 yrs: cycle length variance &lt; 9 days</option>
                                                               </select></div></div>
                                                </div>       
                                                <div className="row">
                                                     <div className="col"><div className="input-group"><span className="input-group-text"> Frequency </span><select className="form-control" value={this.state.frequency} onChange={e => this.setState({ frequency: e.target.value })}>
                                                               <option value="">Choose</option>
                                                               <option value="Absent">Absent</option>
                                                               <option value="Infrequent greater than 38 days">Infrequent {'>'} 38 days</option>
                                                               <option value="Normal 24-38">Normal 24-38</option>
                                                               <option value="Frequent  less than 24">Frequent  &lt;  24</option>
                                                               </select><input type="text" value={this.state.frequencyExact} onChange={e => this.setState({ frequencyExact: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                    <div className="col"></div>
                                                </div>       
                                                    </div>
                                                 </div>   
                                                                                         
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Volume </span><select className="form-control" value={this.state.volume} onChange={e => this.setState({ volume: e.target.value })}>
                                                    <option value="">Choose</option>
                                                                <option value="Heavy">Heavy</option>
                                                               <option value="Normal">Normal</option>
                                                               <option value="Light">Light</option>
                                                               </select><input type="text" value={this.state.volumeExact} onChange={e => this.setState({ volumeExact: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Dysmemorrhoea  </span> <select className="form-control" value={this.state.dysmemorrhoea} onChange={e => this.setState({ dysmemorrhoea: e.target.value })}>
                                                    <option value="">Choose</option>
                                                      <option value="Mild">Mild</option>
                                                               <option value="Mild and takes Meds">Mild & takes Meds</option>
                                                               <option value="Moderate">Moderate</option>
                                                               <option value="Moderate and takes Meds">Moderate & takes Meds</option> 
                                                               <option value="Severe">Severe</option>
                                                               <option value="Severe and takes Meds">Severe & takes Meds</option>
                                                               <option value="No">No</option>
                                                               </select><input type="text" value={this.state.dysmemorrhoeaNotes} onChange={e => this.setState({ dysmemorrhoeaNotes: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">PMS</span> <select className="form-control" value={this.state.pms} onChange={e => this.setState({ pms: e.target.value })}>
                                                    <option value="">Choose</option>
                                                     <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                               </select><input type="text" value={this.state.pmsNotes} onChange={e => this.setState({ pmsNotes: e.target.value })} className="form-control" /></div></div>
                                                </div>    
                                                <div className="card card-green">
                                                <div className="card-header">Contraceptives</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                                <table className="table table-striped">
                                                  <tr>
                                                      <th>Name</th>
                                                      <th>Start Date</th>
                                                      <th>End Date</th>
                                                      <td>Notes</td>
                                                      <th></th>
                                                      </tr>  
                                                    <tbody>
                                                    {this.state.contraceptives.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.name} onChange={e => { this.setContraceptive(index, 'name', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.startDate} onChange={e => { this.setContraceptive(index, 'startDate', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.endDate} onChange={e => { this.setContraceptive(index, 'endDate', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.notes} onChange={e => { this.setContraceptive(index, 'notes', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteContraceptivesRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                        </tr> 
                                                         ))}
                                                    </tbody>  
                                                    </table>  
                                                    </div>
                                                    <button onClick={() => this.addContraceptivesTable()} className="btn btn-primary" >Insert New Contraceptive Line</button> 
                                                    </div> </div>     
                                                    <div className="card card-green">
                                                <div className="card-header">Sexual Health</div>
                                                <div className="card-body">                                
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Dyspareunia</span><select className="form-control" value={this.state.dyspareunia} onChange={e => this.setState({ dyspareunia: e.target.value })}>
                                                    <option value="">Choose</option>
                                                              <option value="Yes">Yes</option>
                                                               <option value="No">No </option>
                                                               </select><input type="text" className="form-control" value={this.state.dyspareuniaNotes} onChange={e => this.setState({ dyspareuniaNotes: e.target.value })} /> </div></div>                                                   
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Dyspareunia - Yes</span> <select className="form-control" value={this.state.dyspareuniaYes} onChange={e => this.setState({ dyspareuniaYes: e.target.value })}>
                                                    <option value="">Choose</option>
                                                               <option value="Superficial">Superficial</option>
                                                               <option value="Deep">Deep</option>
                                                               <option value="Other">Other</option>
                                                               </select><input type="text" className="form-control" value={this.state.dyspareuniaYesNotes} onChange={e => this.setState({ dyspareuniaYesNotes: e.target.value })} /> </div></div>
                                                </div>
                                                </div></div>
                                                    <div className="card card-green">
                                                <div className="card-header">Cervical Cancer Screening</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                                <table className="table table-striped">
                                                  <tr>
                                                      <th>Year</th>
                                                    <th>Month</th>
                                                      <th>Type</th>
                                                      <th>Result</th>
                                                      <th></th>
                                                      </tr>  
                                                    <tbody>
                                                    {this.state.cervicalCancerScreenings.map((item, index) => (
                                                    <tr>
                                                        <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setCervicalCancerScreening(index, 'year', e.target.value); }} /></td>
                                                         <td><input type="text" className="form-control" value={item.month} onChange={e => { this.setCervicalCancerScreening(index, 'month', e.target.value); }} /></td>
                                                       <td><select className="form-control" value={item.type} onChange={e => { this.setCervicalCancerScreening(index, 'type', e.target.value); }}>
                                                        <option value="">Choose</option>
                                                        <option value="VIAC">VIAC</option>
                                                               <option value="PAP">PAP</option>
                                                               <option value="LBC">LBC</option>
                                                               <option value="HPV">HPV</option>
                                                               <option value="LBC or HPV">LBC / HPV</option>
                                                            </select></td>
                                                        <td><input type="text" className="form-control" value={item.result} onChange={e => { this.setCervicalCancerScreening(index, 'result', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteCervicalCancerScreeningRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                        </tr> 
                                                         ))}
                                                    </tbody>  
                                                    </table>   
                                                    </div>
                                                    <button onClick={() => this.addCervicalCancerScreeningTable()} className="btn btn-primary" >Insert New Cervical Cancer Screening Line</button> 
                                                    </div> </div>
                                                    <div className="card card-green">
                                                <div className="card-header">Breast Cancer Screening</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                                <table className="table table-striped">
                                                  <tr>
                                                    <th>Year</th>
                                                    <th>Month</th>
                                                      <th>Self Exam</th>
                                                      <th>Clinical Exam</th>
                                                      <th>Mammogram</th>
                                                      <th>Breast Ultrasound Scan</th>
                                                      <th></th>
                                                      </tr>  
                                                    <tbody>
                                                    {this.state.breastCancerScreenings.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control"  value={item.year} onChange={e => { this.setBreastCancerScreening(index, 'year', e.target.value); }}/></td>
                                                        <td><input type="text" className="form-control" value={item.month} onChange={e => { this.setBreastCancerScreening(index, 'month', e.target.value); }} /></td>
                                                        <td><div className="input-group"><select className="form-control" value={item.selfExam} onChange={e => { this.setBreastCancerScreening(index, 'selfExam', e.target.value); }}>
                                                        <option value="">Choose</option>
                                                        <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                            </select><input type="text" className="form-control" value={item.selfExamRemarks} onChange={e => { this.setBreastCancerScreening(index, 'selfExamRemarks', e.target.value); }} /></div></td>
                                                        
                                                            <td><div className="input-group"><select className="form-control" value={item.clinicalExam} onChange={e => { this.setBreastCancerScreening(index, 'clinicalExam', e.target.value); }}>
                                                            <option value="">Choose</option>
                                                        <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                            </select><input type="text" className="form-control" value={item.clinicalExamRemarks} onChange={e => { this.setBreastCancerScreening(index, 'clinicalExamRemarks', e.target.value); }} /></div></td>
                                                        
                                                            <td><div className="input-group"><select className="form-control" value={item.mammogram} onChange={e => { this.setBreastCancerScreening(index, 'mammogram', e.target.value); }}>
                                                            <option value="">Choose</option>
                                                        <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                            </select><input type="text" className="form-control" value={item.mammogramRemarks} onChange={e => { this.setBreastCancerScreening(index, 'mammogramRemarks', e.target.value); }} /></div></td>

                                                        <td><div className="input-group"><select className="form-control" value={item.breastUltrasoundScan} onChange={e => { this.setBreastCancerScreening(index, 'breastUltrasoundScan', e.target.value); }}>
                                                        <option value="">Choose</option>
                                                        <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                            </select><input type="text" className="form-control" value={item.breastUltrasoundScanRemarks} onChange={e => { this.setBreastCancerScreening(index, 'breastUltrasoundScanRemarks', e.target.value); }} /></div></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteBreastCancerScreeningRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                        </tr> 
                                                         ))}
                                                    </tbody>  
                                                    </table>   
                                                    </div>
                                                    <button onClick={() => this.addBreastCancerScreeningTable()} className="btn btn-primary" >Insert New Breast Cancer Screening Line</button> 
                                                    </div> </div> 
                                                    <div className="card card-green">
                                                <div className="card-header">Menopause</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                                <table className="table table-striped">
                                                  <tr>
                                                      <th>Symptoms</th>
                                                      <th>Other</th>
                                                      <th></th>
                                                      </tr>  
                                                    <tbody>
                                                    {this.state.menopauses.map((item, index) => (
                                                    <tr>
                                                        <td><select className="form-control" value={item.symptoms} onChange={e => { this.setMenopause(index, 'symptoms', e.target.value); }}>
                                                        <option value="">Choose</option>
                                                        <option value="Hot Flushes">Hot Flushes</option>
                                                               <option value="Genitourinary Syndrome">Genitourinary Syndrome</option>
                                                               <option value="Reduced Libido">Reduced Libido </option>
                                                               <option value="Other">Other</option>
                                                            </select></td>
                                                        <td><input type="text" className="form-control" value={item.other} onChange={e => { this.setMenopause(index, 'other', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteMenopauseRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                        </tr> 
                                                         ))}
                                                    </tbody>  
                                                    </table>   
                                                    </div>
                                                    <button onClick={() => this.addMenopauseTable()} className="btn btn-primary" >Insert New Menopause Line</button> 
                                                    </div> </div>   
                                                    <div className="card card-green">
                                                <div className="card-header">Intimate Partner Violence</div>
                                                <div className="card-body">                              
                                                <div className="row">
                                                           <div className="col"><div className="input-group"><span className="input-group-text"> Violence</span> <select className="form-control" value={this.state.violence} onChange={e => this.setState({ violence: e.target.value })}>
                                                    <option value="">Choose</option>
                                                    <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                               </select> </div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Other Information</span><input type="text" className="form-control" value={this.state.otherViolence} onChange={e => this.setState({ otherViolence: e.target.value })} /> </div></div>                                                   
                                                    <div className="col"></div>
                                                </div>
                                            </div> </div>
  <div className="card card-green">
                                                <div className="card-header">Mental Health</div>
                                                <div className="card-body"> 
                                               <div className="row">
                                                    <div className="col"><div className="input-group">
                                                     <textarea className="form-control" value={this.state.mentalHealth} onChange={e => { this.setState({ mentalHealth: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                                </div> </div>
                                            </div>
                                            <div className={this.state.socialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <div className="card card-green">
                                                <div className="card-header">Patient</div>
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.alcoholChoice === "1"} onChange={e => this.setState({ alcoholChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Alcohol</span><input type="text" value={this.state.alcoholText} onChange={e => this.setState({ alcoholText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.smokesChoice === "1"} onChange={e => this.setState({ smokesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Smokes</span><input type="text" value={this.state.smokesText} onChange={e => this.setState({ smokesText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.drugsChoice === "1"} onChange={e => this.setState({ drugsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Drugs</span><input type="text" value={this.state.drugsText} onChange={e => this.setState({ drugsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.churchChoice === "1"} onChange={e => this.setState({ churchChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Church</span><input type="text" value={this.state.churchText} onChange={e => this.setState({ churchText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.bloodTransfusionChoice === "1"} onChange={e => this.setState({ bloodTransfusionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Blood Transfusion</span><input type="text" value={this.state.bloodTransfusionText} onChange={e => this.setState({ bloodTransfusionText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                </div>
                                                </div></div>
                                                <div className="card card-green">
                                                <div className="card-header">Partner</div>
                                                <div className="card-body">
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerAlcoholChoice === "1"} onChange={e => this.setState({ partnerAlcoholChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Alcohol</span><input type="text" value={this.state.partnerAlcoholText} onChange={e => this.setState({ partnerAlcoholText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerSmokesChoice === "1"} onChange={e => this.setState({ partnerSmokesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Smokes</span><input type="text" value={this.state.partnerSmokesText} onChange={e => this.setState({ partnerSmokesText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerDrugsChoice === "1"} onChange={e => this.setState({ partnerDrugsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Drugs</span><input type="text" value={this.state.partnerDrugsText} onChange={e => this.setState({ partnerDrugsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.partnerChurchChoice === "1"} onChange={e => this.setState({ partnerChurchChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Church</span><input type="text" value={this.state.partnerChurchText} onChange={e => this.setState({ partnerChurchText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                    <div className="col"></div>
                                                </div>
                                                </div></div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.socialHistoryTNotes} onChange={e => this.setState({ socialHistoryTNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.diabetesChoice === "1"} onChange={e => this.setState({ diabetesChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Diabetes </span><input type="text" value={this.state.diabetesText} onChange={e => this.setState({ diabetesText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.epilepsyChoice === "1"} onChange={e => this.setState({ epilepsyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Epilepsy</span> <input type="text" value={this.state.epilepsyText} onChange={e => this.setState({ epilepsyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.asthmaChoice === "1"} onChange={e => this.setState({ asthmaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Asthma</span> <input type="text" value={this.state.asthmaText} onChange={e => this.setState({ asthmaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.rheumaticFeverChoice === "1"} onChange={e => this.setState({ rheumaticFeverChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Rheumatic Fever</span>  <input type="text" value={this.state.rheumaticFeverText} onChange={e => this.setState({ rheumaticFeverText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.thyroidDisorderChoice === "1"} onChange={e => this.setState({ thyroidDisorderChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thyroid Disorder </span><input type="text" value={this.state.thyroidDisorderText} onChange={e => this.setState({ thyroidDisorderText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hypertensionChoice === "1"} onChange={e => this.setState({ hypertensionChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hypertension</span> <input type="text" value={this.state.hypertensionText} onChange={e => this.setState({ hypertensionText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.pastMedicalNotes} onChange={e => this.setState({ pastMedicalNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                                <div className="card card-green">
                                                <div className="card-header">Surgeries Done</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>Year</th>
                                                       <th>Surgery</th>
                                                       <th>Type</th>
                                                       <th>Hospital</th>
                                                       <th>Surgeon</th>
                                                       <th>Complications</th>
                                                       <th></th>
                                                   </tr>
                                                    {this.state.surgeries.map((item, index) => (
                                                   <tr key={index}>
                                                   <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setSurgery(index, 'year', e.target.value); }} /></td>
                                                   <td><input type="text" className="form-control" value={item.surgery} onChange={e => { this.setSurgery(index, 'surgery', e.target.value); }} /></td>
                                                   <td><input type="text" className="form-control" value={item.type} onChange={e => { this.setSurgery(index, 'type', e.target.value); }} /></td>
                                                   <td><input type="text" className="form-control" value={item.hospital} onChange={e => { this.setSurgery(index, 'hospital', e.target.value); }} /></td>
                                                   <td><input type="text" className="form-control" value={item.surgeon} onChange={e => { this.setSurgery(index, 'surgeon', e.target.value); }} /></td> 
                                                   <td><input type="text" className="form-control" value={item.complications} onChange={e => { this.setSurgery(index, 'complications', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deleteSurgeriesRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                   </tr>
                                                    ))}
                                                   </table>
                                                   </div>
                                                    <button onClick={() => this.addSurgeriesTable()} className="btn btn-primary" >Insert New Surgeries Done Line</button> 
                                                   </div></div>
                                                   <div className="card card-green">
                                                <div className="card-header">Previous Hospital Admissions</div>
                                                <div className="card-body">
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>Year</th>
                                                       <th>Reason</th>
                                                       <th></th>
                                                   </tr>
                                                   {this.state.previousHospitalAdmissions.map((item, index) => (
                                                   <tr key={index}>
                                                   <td><input type="text" className="form-control" value={item.year} onChange={e => { this.setPreviousHospitalAdmission(index, 'year', e.target.value); }} /></td>
                                                   <td><input type="text" className="form-control" value={item.reason} onChange={e => { this.setPreviousHospitalAdmission(index, 'reason', e.target.value); }} /></td>
                                                               <td ><span className="link-red" onClick={() => this.deletePreviousHospitalAdmissionRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                   </tr>
                                                    ))}
                                                   </table>
                                                   <button onClick={() => this.addPreviousHospitalAdmission()} className="btn btn-primary" >Insert New Previous Hospital Admissions Line</button> 
                                                   </div></div>
                                            </div>

                                            <div className={this.state.postNatalT ? "tab-pane fade show active" : "tab-pane fade"}>
                                             <div className="card card-green">
                                                <div className="card-header">Post Natal Mother</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>#</th>
                                                       <th>Type</th>
                                                       <th>Date</th>
                                                       <th>Time</th>
                                                       <th>Blood Pressure</th>
                                                       <th>Pulse</th>
                                                       <th>Resps</th>
                                                       <th>Temp</th>
                                                       <th>PV Bleeding</th>
                                                       <th>Uterus</th>
                                                       <th>Bladder</th>
                                                       <th></th>
                                                   </tr>
                                                   <tbody>
                                                   {this.state.postNatalMothers.map((item, index) => (
                                                       <tr key={index}>
                                                        <td>{index + 1}</td>
                                                           <td><select className="form-control" value={item.type} onChange={e => { this.setPostNatalMother(index, 'type', e.target.value); }}>
                                                           <option value="">Choose</option>
                                                               <option value="One Hour in Labour Ward">One Hour in Labour Ward</option>
                                                               <option value="Two Hours in Post Natal Ward">Two Hours in Post Natal Ward</option>
                                                               <option value="Three Hours in Post Natal Ward">Three Hours in Post Natal Ward</option>
                                                               <option value="Twice Daily until Discharge">Twice Daily until Discharge</option>
                                                               </select></td>
                                                               <td><input type="text" className="form-control" value={item.date} onChange={e => { this.setPostNatalMother(index, 'date', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.time} onChange={e => { this.setPostNatalMother(index, 'time', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.bloodPressure} onChange={e => { this.setPostNatalMother(index, 'bloodPressure', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.pulse} onChange={e => { this.setPostNatalMother(index, 'pulse', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.resps} onChange={e => { this.setPostNatalMother(index, 'resps', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.temp} onChange={e => { this.setPostNatalMother(index, 'temp', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.pvBleeding} onChange={e => { this.setPostNatalMother(index, 'pvBleeding', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.uterus} onChange={e => { this.setPostNatalMother(index, 'uterus', e.target.value); }} /></td>
                                                           <td><input type="text" className="form-control" value={item.bladder} onChange={e => { this.setPostNatalMother(index, 'bladder', e.target.value); }} /></td>
                                                             <td ><span className="link-red" onClick={() => this.deletePostNatalMotherRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                       </tr> ))}
                                                   </tbody>
                                               </table>
                                               </div>
                                                <button onClick={() => this.addPostNatalMotherTable()} className="btn btn-primary" >Insert New Post Natal Mother Line</button>
                                               </div></div>
                                               <div className="card card-green">
                                                <div className="card-header">Post Natal Baby</div>
                                                <div className="card-body">
                                                <div className="table-responsive-lg"> 
                                               <table className="table table-striped">
                                                   <tr>
                                                       <th>#</th>
                                                       <th>Type</th>
                                                       <th>Date</th>
                                                       <th>Time</th>
                                                       <th>Temp</th>
                                                       <th>Resps</th>
                                                       <th>Cord</th>
                                                       <th>Skin</th>
                                                       <th colSpan="2">Feeding</th>
                                                       <th colSpan="2">Elimination</th>
                                                       <th></th>
                                                   </tr>
                                                   <tr>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th></th>
                                                       <th>BF</th>
                                                       <th>AF</th>
                                                       <th>Bowel</th>
                                                       <th>Urine</th>
                                                       <th></th>
                                                   </tr>
                                                   <tbody>
                                                   {this.state.postNatalBabys.map((item, index) => (
                                                         <tr key={index}>
                                                         <td>{index + 1}</td>
                                                            <td><select className="form-control" value={item.type} onChange={e => { this.setPostNatalBaby(index, 'type', e.target.value); }}>
                                                            <option value="">Choose</option>
                                                                <option value="One Hour in Labour Ward">One Hour in Labour Ward</option>
                                                                <option value="Two Hours in Post Natal Ward">Two Hours in Post Natal Ward</option>
                                                                <option value="Three Hours in Post Natal Ward">Three Hours in Post Natal Ward</option>
                                                                <option value="Twice Daily until Discharge">Twice Daily until Discharge</option>
                                                                </select></td>
                                                                <td><input type="text" className="form-control" value={item.date} onChange={e => { this.setPostNatalBaby(index, 'date', e.target.value); }} /></td>
                                                             <td><input type="text" className="form-control" value={item.time} onChange={e => { this.setPostNatalBaby(index, 'time', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.temp} onChange={e => { this.setPostNatalBaby(index, 'temp', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.resps} onChange={e => { this.setPostNatalBaby(index, 'resps', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.cord} onChange={e => { this.setPostNatalBaby(index, 'cord', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.skin} onChange={e => { this.setPostNatalBaby(index, 'skin', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.bfFeeding} onChange={e => { this.setPostNatalBaby(index, 'bfFeeding', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.afFeeding} onChange={e => { this.setPostNatalBaby(index, 'afFeeding', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.bowelElimination} onChange={e => { this.setPostNatalBaby(index, 'bowelElimination', e.target.value); }} /></td>
                                                            <td><input type="text" className="form-control" value={item.urineElimination} onChange={e => { this.setPostNatalBaby(index, 'urineElimination', e.target.value); }} /></td>
                                                              <td ><span className="link-red" onClick={() => this.deletePostNatalBabyRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                                        </tr> ))}
                                                   </tbody>
                                               </table>
                                               </div>
                                                <button onClick={() => this.addPostNatalBabyTable()} className="btn btn-primary" >Insert New Post Natal Baby Line</button>
                                               </div></div>
                                            </div>
                    <div className={this.state.billingDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                    <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" onChange={e => this.getPreConfigured(e.target.value)}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Pre Configured Procedures</span>
                                                    <select className="form-control" value={this.state.procedure} onChange={e => this.setState({ procedure: e.target.value })}  >
                                                    <option value="">Choose Pre Configured Procedures</option>
                                                    {this.state.procedures.map((item, index) => (
                                                        <option key={index} value={index} >{item.name}</option>
                                                    ))}
                                                </select></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyPcp} onChange={e => this.setState({ cashOnlyPcp: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.procedure >= 0 && (<input type="button" onClick={() => this.addPreconfigs()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                      <div className="card"><div className="card-body">
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.serviceCostId !== "" && (<input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><button className="btn btn-success" onClick={() => this.getProductItems()} ><i class="bi bi-search"></i></button></div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === ''?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            
                                            <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyDs} onChange={e => this.setState({ cashOnlyDs: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                             <div className="col">{ this.state.productItemLocationId !== "" && this.state.quantity > 0 && (<input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" />)}</div>

                                        </div>
                                        </div></div>
                                            <br />
                                    <div className="card  card-blue">
                                    <div className="card-header">Prescription</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) =>(
                                                    <tr key={index}>
                                                        <td>{item.drugName} </td>
                                                        <td>{item.dosage}</td>
                                                        <td>{item.frequencyName}</td>
                                                        <td>{item.routeName} </td>
                                                        <td>{item.durationName}</td>
                                                        <td>{item.formulationName} </td>
                                                        <td>{item.repeats}</td>
                                                         </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div></div>
                                            <br />
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Name</th>
                                                    <th>Tariff</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Cash Only</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1 &&(<tr >
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td><DatePicker selected={new Date(billItem.billStartDate)} onChange={e => this.setBillItem("billStartDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        <td><DatePicker selected={new Date(billItem.billEndDate)} onChange={e => this.setBillItem("billEndDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td>{billItem.authoAmount}</td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                                    </tr>)
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>    
                                         <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                                                </div>            
                                         
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>
                                            </div>
                                            </div>
                                            </div>
                </div>
                <div className="row">
                    <div className="col" align="right">{ this.state.showButton && <button onClick={() => this.save()} className="btn btn-primary">Save</button>}</div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      