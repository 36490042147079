import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Companys extends Component {
    state = { list: [], id: 0, name: '', imageFile: '', file: '', activeStatus: 1, displayName: '', ahfoz: '', phoneNumberId: '', authToken: '', showWhatsapp: 0, showEmail: 0, contactPerson: '', creditsPerSecond: '', address1: '', address2: '',town: '', country: '', email: '', phoneNumber: '', hospital: 0, allowH263: 0, testH263: 0, hostRoot: '', showMobile: 0, cellphone: '', url: '', nh263host: '', companyCode: '', softwareKey: 0, username: '', password: '', sender: '',margin: 0, merchantId: '', merchantKey:'', baseCurrencyId: '', tradingCurrencyId: '', speciality: '', defaultSellLocation: 0, msg: '', error: '', currencys: [], specialitys:[] , imageType: '', imageTypeName: '' }
    componentDidMount() {
        this.getList()
        axios.get(localStorage.getItem('host') + '/auth/menus', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ menus: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    companyAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({id: 0, name: '', activeStatus: 1, displayName: '', ahfoz: '', phoneNumberId: '', authToken: '', showWhatsapp: 0, showEmail: 0, contactPerson: '', creditsPerSecond: '', address1: '', address2: '',town: '', country: '', email: '', phoneNumber: '', hospital: 0, allowH263: 0, testH263: 0, hostRoot: '', showMobile: 0, cellphone: '', url: '', nh263host: '', companyCode: '', softwareKey: 0, username: '', password: '', sender: '',margin: 0, merchantId: '', merchantKey:'', baseCurrencyId: null, tradingCurrencyId: null, speciality: '', defaultSellLocation: null})   
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/auth/companyaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, activeStatus: res.data.activeStatus, displayName: res.data.displayName, ahfoz: res.data.ahfoz, phoneNumberId: res.data.phoneNumberId, authToken: res.data.authToken, showWhatsapp: res.data.showWhatsapp, showEmail: res.data.showEmail, contactPerson: res.data.contactPerson, creditsPerSecond: res.data.creditsPerSecond, address1: res.data.address1, address2: res.data.address2,town: res.data.town, country: res.data.country, email: res.data.email, phoneNumber: res.data.phoneNumber, hospital: res.data.hospital, allowH263: res.data.allowH263, testH263: res.data.testH263, hostRoot: res.data.hostRoot, showMobile: res.data.showMobile, cellphone: res.data.cellphone, url: res.data.url, nh263host: res.data.nh263host, companyCode: res.data.companyCode, softwareKey: res.data.softwareKey, username: res.data.username, password: res.data.password, sender: res.data.sender,margin: res.data.margin, merchantId: res.data.merchantId, merchantKey: res.data.merchantKey, baseCurrencyId: res.data.baseCurrencyId, tradingCurrencyId: res.data.tradingCurrencyId, speciality: res.data.speciality, defaultSellLocation: res.data.defaultSellLocation, specialitys: res.data.specialitys, currencys: res.data.currencys });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#companyAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#companyAction').style = 'display:none'
        console.log('......close');
    }
    saveCompany() {
        var data = { id: this.state.id, name: this.state.name, activeStatus: this.state.activeStatus , displayName: this.state.displayName, ahfoz: this.state.ahfoz, phoneNumberId: this.state.phoneNumberId, authToken: this.state.authToken, showWhatsapp: this.state.showWhatsapp, showEmail: this.state.showEmail, contactPerson: this.state.contactPerson, creditsPerSecond: this.state.creditsPerSecond, address1: this.state.address1, address2: this.state.address2,town: this.state.town, country: this.state.country, email: this.state.email, phoneNumber: this.state.phoneNumber, hospital: this.state.hospital, allowH263: this.state.allowH263, testH263: this.state.testH263, hostRoot: this.state.hostRoot, showMobile: this.state.showMobile, cellphone: this.state.cellphone, url: this.state.url, nh263host: this.state.nh263host, companyCode: this.state.companyCode, softwareKey: this.state.softwareKey, username: this.state.username, password: this.state.password, sender: this.state.sender,margin: this.state.margin, merchantId: this.state.merchantId, merchantKey: this.state.merchantKey, baseCurrencyId: this.state.baseCurrencyId, tradingCurrencyId: this.state.tradingCurrencyId, speciality: this.state.speciality, defaultSellLocation: this.state.defaultSellLocation }
        axios.post(localStorage.getItem('host') + '/auth/companyform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/company', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span><span className="link" title="Edit Company" id={res.data[index].id} onClick={e => this.companyAction(e)}><i className="bi bi-pencil-square"></i></span>
                    <span className="link" title="Upload Logo" id={res.data[index].id} onClick={e => this.uploadLogo(e)}><i class="bi bi-cloud-upload-fill"></i></span>
                    <span className="link" title="Upload Stamp" id={res.data[index].id} onClick={e => this.uploadImage(e, "stamp_")}><i class="bi bi-cloud-upload-fill"></i></span></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    uploadLogo(e)
      {
        var id = e.currentTarget.id;
        this.setState({id:id, imageFile: '', file: ''})
        axios.get(localStorage.getItem('host') + '/auth/getlogocompany/'+id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                 this.setState({ imageFile: res.data });
                 document.getElementById('logoAction').style = 'display:block'
            }, err => {
                console.log(".....")
            }
        )
        
      }
      close() {
          document.getElementById('logoAction').style = 'display:none'
      }
      saveLogo()
      {
        var data = new FormData();
        data.append("file", this.state.file)
        axios.post(localStorage.getItem('host') + '/auth/uploadlogo/'+this.state.id, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.close();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
      }
      uploadImage(e, imageType)
        {
          var id = e.currentTarget.id;
          this.setState({id:id, imageFile: '', file: '', imageType: imageType, imageTypeName: imageType === "stamp_"?"Stamp":"Signature"})
          axios.get(localStorage.getItem('host') + '/auth/getimagecompany/'+id+"/"+imageType, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          }).then(
              res => {
                   this.setState({ imageFile: res.data });
                   document.getElementById('imageAction').style = 'display:block'
              }, err => {
                  console.log(".....")
              }
          )
          
        }
        closeImage() {
            document.getElementById('imageAction').style = 'display:none'
        }
        saveImage()
        {
          var data = new FormData();
          data.append("file", this.state.file)
          axios.post(localStorage.getItem('host') + '/auth/uploadimage/'+this.state.id+"/"+this.state.imageType, data, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'content-type': 'multipart/form-data'
              }
          }).then(
              res => {
                  console.log(res.data)
                  this.setState({ msg: res.data });
                  this.closeImage();
                  setTimeout(() => {
                      this.setState({ msg: '' });
                  }, 3000);
              }, err => {
                  console.log(".....")
              }
          )
        }
    render() {
        const data = {
            columns: [
                
                {
                    span: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    span: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    span: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    span: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col"><a href="/file/BioReaderApp.zip" target="_blank" download>Download Bio Reader Driver</a></div>
                    </div>
                <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Company" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                         <div className="page-popup page-popup-overlay" id="logoAction">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Logo Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><img alt="Logo" src={this.state.imageFile} style={{width: 300+'px'}} /></div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Logo</span>
                                                <input type="file" accept=".jpeg, .jpg" className="form-control"  onChange={e => { this.setState({ file: e.target.files[0] }) }} /> </div>
                                            </div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveLogo()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Close" /></div>
                                        </div>
                                     </div>
                                 </div>     
                                 </div>      
                         <div className="page-popup page-popup-overlay" id="imageAction">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        {this.state.imageTypeName} Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><img alt="Signature or Stamp" src={this.state.imageFile} style={{width: 300+'px'}} /></div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Image</span>
                                                <input type="file" accept=".png" className="form-control"  onChange={e => { this.setState({ file: e.target.files[0] }) }} /> </div>
                                            </div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveImage()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closeImage()} value="Close" /></div>
                                        </div>
                                     </div>
                                 </div>     
                                 </div>   
                        <div className="page-popup page-popup-overlay" id="companyAction">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Company Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  > 
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Display Name</span>
                                                <input type="text" className="form-control" value={this.state.displayName} onChange={e => { this.setState({ displayName: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Ahfoz</span>
                                                <input type="text" className="form-control" value={this.state.ahfoz} onChange={e => { this.setState({ ahfoz: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Phone Number Id</span>
                                                <input type="text" className="form-control" value={this.state.phoneNumberId} onChange={e => { this.setState({ phoneNumberId: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Auth Token</span>
                                                <textarea type="text" className="form-control" value={this.state.authToken} onChange={e => { this.setState({ authToken: e.target.value }) }} ></textarea> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Whatsapp Show</span>
                                                 <select className="form-control" value={this.state.showWhatsapp} onChange={e => { this.setState({ showWhatsapp: e.target.value }) }}  > 
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Email Show</span>
                                                 <select className="form-control" value={this.state.showEmail} onChange={e => { this.setState({ showEmail: e.target.value }) }}  > 
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Contact Person</span>
                                                <input type="text" className="form-control" value={this.state.contactPerson} onChange={e => { this.setState({ contactPerson: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Credits Per Second</span>
                                                <input type="text" className="form-control" value={this.state.creditsPerSecond} onChange={e => { this.setState({ creditsPerSecond: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Address1</span>
                                                <input type="text" className="form-control" value={this.state.address1} onChange={e => { this.setState({ address1: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Address2</span>
                                                <input type="text" className="form-control" value={this.state.address2} onChange={e => { this.setState({ address2: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Town</span>
                                                <input type="text" className="form-control" value={this.state.town} onChange={e => { this.setState({ town: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Country</span>
                                                <input type="text" className="form-control" value={this.state.country} onChange={e => { this.setState({ country: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Email</span>
                                                <input type="text" className="form-control" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Phone Number</span>
                                                <input type="text" className="form-control" value={this.state.phoneNumber} onChange={e => { this.setState({ phoneNumber: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Host Root</span>
                                                <input type="text" className="form-control" value={this.state.hostRoot} onChange={e => { this.setState({ hostRoot: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Hospital</span>
                                                <select className="form-control" value={this.state.hospital} onChange={e => { this.setState({ hospital: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Allow H263</span>
                                                <select className="form-control" value={this.state.allowH263} onChange={e => { this.setState({ allowH263: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Test H263</span>
                                                <select className="form-control" value={this.state.testH263} onChange={e => { this.setState({ testH263: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Cellphone</span>
                                                <input type="text" className="form-control" value={this.state.cellphone} onChange={e => { this.setState({ cellphone: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Show Mobile</span>
                                                <select className="form-control" value={this.state.showMobile} onChange={e => { this.setState({ showMobile: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Deactivate</option>
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Url</span>
                                                <input type="text" className="form-control" value={this.state.url} onChange={e => { this.setState({ url: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Company Code</span>
                                                <input type="text" className="form-control" value={this.state.companyCode} onChange={e => { this.setState({ companyCode: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Software Key</span>
                                                <input type="text" className="form-control" value={this.state.softwareKey} onChange={e => { this.setState({ softwareKey: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Username</span>
                                                <input type="text" className="form-control" value={this.state.username} onChange={e => { this.setState({ username: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Password</span>
                                                <input type="text" className="form-control" value={this.state.password} onChange={e => { this.setState({ password: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Sender</span>
                                                <input type="text" className="form-control" value={this.state.sender} onChange={e => { this.setState({ sender: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Margin</span>
                                                <input type="text" className="form-control" value={this.state.margin} onChange={e => { this.setState({ margin: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Merchant Id</span>
                                                <input type="text" className="form-control" value={this.state.merchantId} onChange={e => { this.setState({ merchantId: e.target.value }) }} /> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Merchant Key</span>
                                                <input type="text" className="form-control" value={this.state.merchantKey} onChange={e => { this.setState({ merchantKey: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Base Currency</span>
                                                <select className="form-control" value={this.state.baseCurrencyId} onChange={e => { this.setState({ baseCurrencyId: e.target.value }) }}  >
                                                <option value="null" >Choose Currency</option>
                                                    {this.state.currencys.map((currency, index) => (
                                                        <option key={index} value={currency.id} >{currency.name}</option>
                                                    ))}
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Trading Currency</span>
                                                <select className="form-control" value={this.state.tradingCurrencyId} onChange={e => { this.setState({ tradingCurrencyId: e.target.value }) }}  >
                                                    <option value="null" >Choose Currency</option>
                                                    {this.state.currencys.map((currency, index) => (
                                                        <option key={index} value={currency.id} >{currency.name}</option>
                                                    ))}
                                                </select> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Speciality</span>
                                                <select className="form-control" value={this.state.speciality} onChange={e => { this.setState({ speciality: e.target.value }) }}  >
                                                {this.state.specialitys.map((speciality, index) => (
                                                        <option key={index} value={speciality} >{speciality}</option>
                                                    ))}
                                                </select> </div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Nh263host</span>
                                                <input type="text" className="form-control" value={this.state.nh263host} onChange={e => { this.setState({ nh263host: e.target.value }) }} /> </div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"></div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveCompany()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div></div>
        )

    }
}    