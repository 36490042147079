import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate())+' '+checkZero(now.getHours())+':'+checkZero(now.getMinutes())+':'+checkZero(now.getSeconds());
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class AdvancedReceipt extends Component {
   state = { id: 0, patientId: 0, companyId: '', companyIdError: '', paymentTypeId: '', paymentTypeIdError: '', paidAmount: '', paidAmountError: '', receiptDateTime: date1, receiptDateTimeError: '', companys: [], paymentTypes: [], msg: '' , error: '',
    fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: ''}
   getAdvancedReceiptRef(id)
    {
        this.setState({patientId: id, })
        axios.get(localStorage.getItem('host') + '/patient/getreceiptitem/'+id+'/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile,  gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, companys: res.data.companys });
                this.validate()
                this.props.setLoader(false)

            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    doValidate()
    {
      setTimeout(() => {
          this.validate();
      }, 1000);   
    }
    validate()
      {
         console.log((/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.test(this.state.receiptDateTime)))
        var error = false;
        if (this.state.receiptDateTime === null) {
            this.setState({ receiptDateTimeError: "Receipt Date Time cannot be empty" });
            error = true
        }
        else if (this.state.receiptDateTime === "") {
            this.setState({ receiptDateTimeError: "Receipt Date Time cannot be empty" });
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.test(this.state.receiptDateTime)))
            {
            this.setState({ receiptDateTimeError: "Receipt Date Time wrong date format"})
            error = true
            }
        else {
            this.setState({ receiptDateTimeError: "" })
        }

        if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" })
            error = true
        }
        else if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" })
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" })
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        
        if (this.state.paymentTypeId === null) {
            this.setState({ paymentTypeIdError: "Payment Type cannot be empty" })
            error = true
        }
        else if (this.state.paymentTypeId === 0) {
            this.setState({ paymentTypeIdError: "Payment Type cannot be empty" })
            error = true
        }
        else if (this.state.paymentTypeId === "") {
            this.setState({ paymentTypeIdError: "Payment Type cannot be empty" })
            error = true
        }
        else {
            this.setState({ paymentTypeIdError: "" })
        } 

        if (this.state.paidAmount === null) {
            this.setState({ paidAmountError: "Paid Amount cannot be empty" })
            error = true
        }
        else if (this.state.paidAmount === "") {
            this.setState({ paidAmountError: "Paid Amount cannot be empty" })
            error = true
        }
        else if(!(/^[0-9].*$/.test(this.state.paidAmount)))
            {
            this.setState({ paidAmountError: "Paid Amount should be a number"})
            error = true
            }
        else if(this.state.paidAmount  < 0)
               {
                this.setState({ paidAmountError:"Paid Amount cannot be less than 0"})
                error = true
               }
        else {
            this.setState({ paidAmountError: "" })
        }
     
        if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }  
      }
    save() {
            this.props.setLoader(true)
            var data = { id: this.stateId, patientId: this.state.patientId, companyId: this.state.companyId, paymentTypeId: this.state.paymentTypeId , paidAmount: this.state.paidAmount, receiptDateTime: this.state.receiptDateTime}
            axios.post(localStorage.getItem('host') + '/bill/saveadvancedreceipt', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                   if(res.data.error)
                       {
                        this.props.setLoader(false)
                         this.setState({error: res.data.error})
                       }
                    else
                      {
                        this.props.setLoader(false)  
                        this.closePopup()
                       this.props.getPrintOut(0, '', res.data.printouts)   
                      }   
                }, err => {
                    console.log(".....")
                     this.setState({error: err+""})
                this.props.setLoader(false)
                }
            )
    }
    getPaymentMethods(id)
     {
      this.setState({paymentTypes: [], companyId: id, paymentTypeId: ''})
      if(id !== "")  
        { 
                for(var i in this.state.companys)
                    {
                    if(this.state.companys[i].id === Number(id)) 
                    {
                        this.setState({paymentTypes: this.state.companys[i].paymentTypes})   
                    }

                    }
            }
     }
    processDate(date)
      {
        const now = new Date(date);
        var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate())+' '+checkZero(now.getHours())+':'+checkZero(now.getMinutes())+':'+checkZero(now.getSeconds());
       return date1;
        

      }
    closePopup() {
        document.getElementById('advancedReceiptAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="advancedReceiptAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Advanced Receipt Action</div>
            {divMsg}
            <div className="card-body" >
                <div className="card border-success" >
                    <div className="card-body">
                        <div className="row">
                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                           <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                           <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                        </div>
                        <div className="row">
                            <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                           <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                           <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={ { __html: this.state.age}}></span></div>
                            </div>
                    </div>
                </div>
                <div >
                    <div className="row">
                        <div className="col">
                            <div className="input-group has-validation"><span className="input-group-text">Receipt Date</span>
                                <DatePicker  className={this.state.receiptDateTimeError !== "" ? "form-control is-invalid" : "form-control"} selected={new Date(this.state.receiptDateTime)} onChange={(date) => {this.setState({ receiptDateTime: this.processDate(date) }); this.doValidate() }} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" />
                                <input type="hidden" className={this.state.receiptDateTimeError !== "" ? "form-control is-invalid" : "form-control"} /><div className="invalid-feedback">{this.state.receiptDateTimeError}</div> </div></div>
                        <div className="col">
                            <div className="input-group has-validation"><span className="input-group-text">Company</span>
                                <select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e => {this.getPaymentMethods(e.target.value); this.doValidate()}}  >
                                    <option value="">Choose Company</option>
                                    {this.state.companys.map((item, index) => (
                                        <option key={index} value={item.id} >{item.name}</option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">{this.state.companyIdError}</div></div></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="input-group has-validation"><span className="input-group-text">Payment Type</span>
                                <select className={this.state.paymentTypeIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e => {this.setState({ paymentTypeId: e.target.value }); this.doValidate()}}  >
                                    <option value="">Choose Payment Type</option>
                                    {this.state.paymentTypes.map((item, index) => (
                                        <option key={index} value={item.id} >{item.name}</option>
                                    ))}
                                </select>
                                <div className="invalid-feedback">{this.state.paymentTypeIdError}</div></div></div>
                        <div className="col">
                            <div className="input-group has-validation"><span className="input-group-text">Paid Amount</span>
                                <input type="text" className={this.state.paidAmountError !== "" ? "form-control is-invalid" : "form-control"} onChange={e => {this.setState({ paidAmount: e.target.value }); this.doValidate()}} />
                                <div className="invalid-feedback">{this.state.paidAmountError}</div></div></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6" align="center">{this.state.showSubmit === true ? (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                        <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                    </div>
                </div>
            </div>
        </div></div>)

    }
}      